import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import axios from "axios"
import Cookies from "universal-cookie"
import { isLoggedIn, getUser } from "../../../services/auth"
import config from "../../../config/config"
import SlideToggle from "react-slide-toggle"
import Layout from "../../../templates/Page"
import Header from "../../ContentBuilder/Header"
import SwiperImageList from "../../ContentBuilder/SwiperImageList"
import CustomDesign from "../../ContentBuilder/SwiperList/CustomDesign"
import GroundFloorList from "./GroundFloorList"
import Level1RoomsList from "./Level1RoomsList"
import HomeDesignRelatedItems from "./HomeDesignRelatedItems"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import {
  getRecentPages,
  setRecentPages,
} from "../../../services/account-services"
import RecentViews from "../../RecentViews"
import FeaturedProducts from "../../Block/FeaturedProducts"
import { Modal } from "react-bootstrap"
import { connect } from "react-redux"
import { getBoardFromLocalStorage } from "../../../state/board"
import ShowMoreText from "react-show-more-text"

const cookies = new Cookies()

var sent = false

const HomeDesignDetail = ({ data, currentBoards, dispatch }) => {
  var user = getUser()
  const host = config.host
  const [homedesign, setHomeDesign] = useState({})
  const [globalConfig, setGlobalConfig] = useState({})
  const [slideVariation, setSlideVariation] = useState({})
  const [activeObjectID, setActiveObjectID] = useState(null)
  const changeSlideImage = slide => {
    setActiveObjectID(slide.objectID)
    setSlideVariation(slide)
    if (typeof window !== "undefined") {
      window.history.replaceState(null, null, "?vid=" + slide.objectID)
    }
  }

  useEffect(() => {
    dispatch(getBoardFromLocalStorage())
  }, [])

  var homedesign_name = homedesign.name
  var storeys = homedesign.Storeys
  var isDoulbeStorey = false
  if (typeof storeys !== "undefined" && storeys) {
    var storeyName = storeys.UndigitalStoreys.name
    if (storeyName === "Double Storey") {
      isDoulbeStorey = true
    }
  }
  var imagePosition = homedesign.imagePosition
  var has_gallery = 0
  var gallery_images = []
  var rrpPrice = parseFloat(Math.round(slideVariation.rrpPrice * 1000) / 1000).toFixed(2)
  var formattedPrice = "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  var lotSize = (slideVariation.lotWidth && slideVariation.lotLength) ? (slideVariation.lotWidth + " x " + slideVariation.lotLength + "m") : null
  var vid = null
  if (typeof window !== "undefined") {
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    vid = params.get("vid")
  }
  useEffect(() => {
    if (data) {
      setGlobalConfig(data.silverStripeDataObject.UndigitalGlobalConfig)
      let _homedesign =
        data.allSilverStripeDataObject.nodes[0].UndigitalHomeDesign
      setHomeDesign(_homedesign)
      let FeaturedFloorPlan = _homedesign.FeaturedFloorPlan
      if (FeaturedFloorPlan !== "undefined" && FeaturedFloorPlan) {
        let featuredVariation = FeaturedFloorPlan.UndigitalFloorplan
        if (!vid) {
          setSlideVariation(featuredVariation)
          if (activeObjectID === null) {
            setActiveObjectID(featuredVariation.objectID)
          }
        }
      }
    }
  }, [JSON.stringify(data)])
  
  if (isDoulbeStorey) {
    if (slideVariation.groundFloorImagePath) {
      var groundFloor_img = {
        UndigitalGalleryImage: {
          imagePath: slideVariation.groundFloorImagePath,
          imagePosition: imagePosition,
        },
      }
      gallery_images.push(groundFloor_img)
    }
    if (slideVariation.firstFloorImagePath) {
      var firstFloor_img = {
        UndigitalGalleryImage: {
          imagePath: slideVariation.firstFloorImagePath,
          imagePosition: imagePosition,
        },
      }
      gallery_images.push(firstFloor_img)
    }
  } else {
    if (slideVariation.groundFloorImagePath) {
      var groundFloor_img = {
        UndigitalGalleryImage: {
          imagePath: slideVariation.groundFloorImagePath,
          imagePosition: imagePosition,
        },
      }
      gallery_images.push(groundFloor_img)
    }
  }

  if (slideVariation) {
    homedesign_name += " " + slideVariation.name
  }
  const nextLink = homedesign.nextLink !== '' ? ('/explore/home-designs/' + homedesign.nextLink) : null
  const prevLink = homedesign.prevLink !== '' ? ('/explore/home-designs/' + homedesign.prevLink) : null
  
  const homedesign_id = homedesign.objectID
  const gallery = homedesign.Gallery
  const imagePath = homedesign.ImagePath
  const designers = homedesign.Designers
  var designersList = []
  var designersName = ""
  if (typeof designers !== "undefined" && designers) {
    var i = 0
    for (; designers[i]; ) {
      designersList[i] = designers[i].UndigitalDesigner.name
      i++
    }
  }
  designersName = designersList.join(", ")
  var disclaimers = []
  if(typeof homedesign.Disclaimer !== 'undefined'){
      disclaimers = homedesign.Disclaimer
  }

  const [allVariations, setAllVariants] = useState([])
  const [isSaved, setIsSaved] = useState(false)
  useEffect(() => {
    var _allVariations = []
    const variations = homedesign.Floorplans
    const valid_status = ["Active"]
    if (typeof variations !== undefined && variations) {
      let i = 0
      for (; variations[i]; ) {
        let variation = variations[i].UndigitalFloorplan
        variation.image = variation.groundFloorImagePath
        let isValid = valid_status.includes(variation.status)
        if (isValid) {
          _allVariations[i] = variation
        }
        i++
      }
    }
    setAllVariants(_allVariations)
    if (vid) {
      setActiveObjectID(vid)
    }

    let found = false
    for (const board of currentBoards) {
      const items = JSON.parse(board.Items)
      for (const key in items) {
        if (
          !found &&
          key.includes("HomeDesign") &&
          items[key].ID == homedesign.objectID &&
          items[key].VariantID == slideVariation.objectID
        ) {
          setIsSaved(true)
          found = true
        }
      }
    }
    if (!found) setIsSaved(false)
  }, [currentBoards, homedesign, slideVariation])
  useEffect(() => {
    if (vid) {
      const currentVariation = allVariations.find(v => v.objectID == vid)
      if (currentVariation) {
        changeSlideImage(currentVariation)
      }
    }
  }, [allVariations])

  const swiper_params = {
    spaceBetween: 10,
    navigation: {
      prevEl: ".swiper-button-prev-unique",
      nextEl: ".swiper-button-next-unique",
    },
    breakpoints: {
      1200: {
        slidesPerView: 6,
      },
      1100: {
        slidesPerView: 5,
      },
      992: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      0: {
        slidesPerView: 2,
      },
    },
  }

  var logged = isLoggedIn() ? 1 : 0
  var user_email = cookies.get("user_email")
  if (typeof user_email === "undefined") {
    user_email = ""
  }
  if (user.Email) {
    user_email = user.Email
  }

  if (homedesign_name && !sent) {
    var user_key = cookies.get("user_key")
    if (typeof user_key === "undefined") {
      var date = new Date()
      var time = date.getTime()
      var days_to_save = 365 * 24 * 60 * 60 * 1000 // 365 days
      cookies.set("user_key", time, {
        path: "/",
        expires: new Date(Date.now() + days_to_save),
      })
    }
    axios
      .get(
        host +
          "/tribecaapi/homedesignview?key=" +
          user_key +
          "&email=" +
          user_email +
          "&id=" +
          homedesign_id +
          "&logged=" +
          logged
      )
      .then(response => {})
      .catch(function (error) {})

    sent = true
  }

  const showAddBoard = () => {
    if (isLoggedIn()) {
      setShowCreateBoard(true)
    } else {
      if (typeof window !== "undefined") {
        window.location.href = "/login/"
      }
    }
  }

  const [showCreateBoard, setShowCreateBoard] = useState(false)
  const [showFloorBoard, setShowFloorBoard] = useState(false)

  const handleFloorShow = () => setShowFloorBoard(true)
  const handleFloorClose = () => setShowFloorBoard(false)

  if (homedesign && homedesign.status === "Active") {
    const recentPages = getRecentPages()
    if (vid) {
      if (
        slideVariation &&
        typeof slideVariation.status !== "undefined" &&
        slideVariation.status == "Active"
      ) {
        homedesign.variant = slideVariation
        setRecentPages([
          { ...homedesign, className: "HomeDesign" },
          ...recentPages.filter(function (rp) {
            return !(
              rp.className == "HomeDesign" &&
              rp.objectID == homedesign.objectID &&
              typeof rp.variant !== "undefined" &&
              rp.variant.objectID == homedesign.variant.objectID
            )
          }),
        ])
      }
    } else {
      setRecentPages([
        { ...homedesign, className: "HomeDesign" },
        ...recentPages.filter(function (rp) {
          return !(
            rp.className == "HomeDesign" &&
            rp.objectID == homedesign.objectID &&
            typeof rp.variant == "undefined"
          )
        }),
      ])
    }
    //setRecentPages([])
  }

  return (
    <Layout>
      <CreateBoardModal
        show={showCreateBoard}
        handleClose={() => setShowCreateBoard(false)}
        objectClass="HomeDesign"
        objectID={homedesign.objectID}
        variantID={activeObjectID}
      />
      <Modal
        className="boardModal pin-top"
        show={showFloorBoard}
        onHide={handleFloorClose}
      >
        <div className="boardModal-inner">
          <Modal.Header
            className="mb-3 d-flex justify-content-end"
            closeButton
          ></Modal.Header>
          <h3 className="mb-4 info-title">House amenity</h3>
          {slideVariation.GroundFloorRoomsAmenities ? (
            <GroundFloorList list={slideVariation.GroundFloorRoomsAmenities} />
          ) : (
            ""
          )}
          {slideVariation.Level1RoomsAmenities ? (
            <Level1RoomsList list={slideVariation.Level1RoomsAmenities} />
          ) : (
            ""
          )}
        </div>
      </Modal>
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/explore/home-designs/">Home Designs</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {homedesign.name}
            </li>
          </ol>
        </nav>
        <div>
          <div className="main-title-wrap">
            <div className="board-page-title">
              <h1 className="product-name-title text-uppercase h2">
                {homedesign.name}
              </h1>
            </div>
          </div>

          <div className="wrap-section row">
            <div className="col-md-8">
              <p
                className="home-design-info d-none d-sm-block"
                dangerouslySetInnerHTML={{ __html: homedesign.description }}
              />
              <ShowMoreText
                    more={<small className="more">Read more</small>}
                    less={<small className="less">Read less</small>}
                    className="home-design-info about-wrap d-block d-sm-none"
                    anchorClass="button-link d-block mt-1 text-right"
                    expanded={false}
                    width={0}
                  >
                    <p dangerouslySetInnerHTML={{ __html: homedesign.description }}
                    />
                  </ShowMoreText>
            </div>
            <div className="col-md-4 information">
              <h3 className="mb-2 info-title">Information</h3>
              <div className="list-group list-group-flush mb-4">
                {globalConfig.hidePricingGlobally == 0 && (
                  <div className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Price from*</h5>
                      </div>
                      <div className="col-auto">
                        <p className="h4">{formattedPrice}</p>
                      </div>
                    </div>
                  </div>
                )}
                {designersList.length > 0 && (
                <div className="list-group-item px-0">
                  <div className="row">
                    <div className="col">
                      <h5 className="mb-0">Designer</h5>
                    </div>
                    <div className="col-auto">
                      <p>{designersName}</p>
                    </div>
                  </div>
                </div>
                )}
                {slideVariation.homeArea && (
                <div className="list-group-item px-0">
                  <div className="row">
                    <div className="col">
                      <h5 className="mb-0">Approx House area</h5>
                    </div>
                    <div className="col-auto">
                      <p>{slideVariation.homeArea} sqm</p>
                    </div>
                  </div>
                </div>
                )}
                {lotSize && (
                <div className="list-group-item px-0">
                  <div className="row">
                    <div className="col">
                      <h5 className="mb-0">Minimum block size</h5>
                    </div>
                    <div className="col-auto">
                      <p>{lotSize}</p>
                    </div>
                  </div>
                </div>
                )}
                {(disclaimers && disclaimers.length) > 0 && (
                  <SlideToggle collapsed="false">
                    {({ toggle, setCollapsibleElement, toggleState }) => (
                      <div className={`list-group-item px-0${
                        toggleState == "EXPANDED" ? " expanded" : ""
                      }`}>
                        <h5
                          className={`mb-0 info-toggle${
                            toggleState == "EXPANDED" ? " active" : ""
                          }`}
                          onClick={e => {
                            toggle(e)
                            e.preventDefault()
                          }}
                        >
                          Disclaimer
                        </h5>
                        <div className="content-wrap" ref={setCollapsibleElement}>  
                            {disclaimers.map((disclaimer, idx) => (                        
                                <div className="mb-2" key={`disclaimer-${idx}`} dangerouslySetInnerHTML={{ __html: disclaimer.UndigitalTextAsset.content }}/>
                            ))}     
                        </div>                      
                      </div>
                    )}
                  </SlideToggle>
                )}
              </div>

              <div className="row justify-content-center mt-30">
                <div className="col-xl-8 col-md-12 col-sm-6 col-8">
                  <a
                    href="/contact-us/"
                    className="btn btn-black btn-block btn-lg px-2 my-4 my-lg-1"
                  >
                    Enquire now
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div
                className="d-flex flex-row justify-content-between align-items-end board-page-title
              home-design-subtitle"
              >
                <div>
                  <span className="product-name-span">layout</span>
                  <h3 className="product-name-title">{slideVariation.name}</h3>
                </div>
                {nextLink && (
                <a href={nextLink} className="nextboard-link h3">
                  <span>Next layout</span>
                </a>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex mx-n2 mb-2 mb-sm-0">
                  <div className="mx-2 item-feature">
                    {slideVariation.bed}
                    <svg width="26" height="26">
                      <use xlinkHref="/img/icons.svg#icon-bed-stroke"></use>
                    </svg>
                  </div>
                  <div className="mx-2 item-feature">
                    {slideVariation.bath}
                    <svg width="26" height="26">
                      <use xlinkHref="/img/icons.svg#icon-bath-stroke"></use>
                    </svg>
                  </div>
                  <div className="mx-2 item-feature">
                    {slideVariation.living}
                    <svg width="26" height="26">
                      <use xlinkHref="/img/icons.svg#icon-sofa-stroke"></use>
                    </svg>
                  </div>
                  <div className="mx-2 item-feature">
                    {slideVariation.garage}
                    <svg width="26" height="26">
                      <use xlinkHref="/img/icons.svg#icon-garages-stroke"></use>
                    </svg>
                  </div>
                </div>

                <button
                  type="button"
                  className="button-link text-decoration-none"
                >
                  {isSaved ? (
                    <div
                      className="d-flex flex-column align-items-center text-black mb-2 mb-md-0 btn-add-to-board"
                      onClick={() => showAddBoard()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                      >
                        <path
                          id="Path_878"
                          data-name="Path 878"
                          d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                          transform="translate(21.982 6.975)"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="1.5"
                        />
                      </svg>
                      LOVED
                    </div>
                  ) : (
                    <div
                      className="d-flex flex-column align-items-center text-black btn-add-to-board"
                      onClick={() => showAddBoard()}
                    >
                      <img src="/img/heart-grey.svg" alt=""/>
                      ADD
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="wrap-section row mt-2 mt-md-4">
            <div className="col-12 col-md-3 d-none d-md-block">
              {slideVariation.GroundFloorRoomsAmenities ? (
                <GroundFloorList
                  list={slideVariation.GroundFloorRoomsAmenities}
                />
              ) : (
                ""
              )}
              {slideVariation.Level1RoomsAmenities ? (
                <Level1RoomsList list={slideVariation.Level1RoomsAmenities} />
              ) : (
                ""
              )}
            </div>
            <div className="col-12 col-md-9 position-relative">
              <div className="toggle-floor" onClick={handleFloorShow}>
                <p>View house amenity</p>
                <svg width="14px" height="26px" className="arrow-ico">
                  <use xlinkHref="/img/icons.svg#arrow-right"></use>
                </svg>
              </div>
              {gallery_images && <CustomDesign homedesign={homedesign} variant={slideVariation} gallery={gallery_images} />}
            </div>
          </div>

          {(allVariations && allVariations.length > 1) && (
            <div className="wrap-section row">
              <div className="col-12">
                <div className="other-available-wrap mb-4 mb-lg-5">
                  <div className="title-swiper">
                    <h3 className="main-title">
                      <span>Other options:</span>
                    </h3>
                    <div className="swiper-button-prev-unique"></div>
                    <div className="swiper-button-next-unique"></div>
                  </div>
                  <div className="other-available other-home-design">
                    <Swiper navigation {...swiper_params}>
                      {allVariations.map((variation, idx) => (
                        <SwiperSlide key={`variation${idx}`}>
                          <div
                            className={`product-items clickable ${
                              variation.objectID === activeObjectID
                                ? "active"
                                : ""
                            }`}
                          >
                            <div
                              className={`product-items-thumb ${imagePosition}`}
                            >
                              <a
                                href={`/explore/home-designs/${homedesign.urlSegment}/?vid=${variation.objectID}`}
                              >
                                <img alt="" src={variation.image} alt="" />
                              </a>
                            </div>
                            <p className="product-items-desc">
                              {variation.name}
                              {globalConfig.hidePricingGlobally == 0 && (
                                <>- {formattedPrice}</>
                              )}
                            </p>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          )}
          <HomeDesignRelatedItems id={homedesign.objectID} vid={vid} />
          <RecentViews />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    silverStripeDataObject(
      className: { eq: "Undigital__Objects__GlobalConfig" }
    ) {
      UndigitalGlobalConfig {
        hidePricingGlobally
      }
    }
    allSilverStripeDataObject(
      filter: {
        UndigitalHomeDesign: {
          urlSegment: { eq: $slug }
          status: { in: ["Active"] }
        }
      }
    ) {
      nodes {
        UndigitalHomeDesign {
          status
          name
          urlSegment
          prevLink
          nextLink
          description
          brochurePath
          objectID          
          homeSize          
          imagePath
          imagePosition
          FeaturedFloorPlan {
            UndigitalFloorplan {
              status
              objectID
              name
              homeArea
              rrpPrice
              lotWidth
              lotLength
              bath
              bed
              garage
              living
              firstFloorImagePath
              groundFloorImagePath
              GroundFloorRoomsAmenities {
                UndigitalRoomAmenity {
                  name
                  objectID
                }
              }
              Level1RoomsAmenities {
                UndigitalRoomAmenity {
                  name
                  objectID
                }
              }
            }
          }
          HomeType {
            UndigitalHomeType {
              name
            }
          }
          Gallery {
            UndigitalGalleryImage {
              imagePath
            }
          }
          Floorplans {
            UndigitalFloorplan {
              status
              objectID
              featuredFloorplan
              name
              homeArea
              rrpPrice
              lotWidth
              lotLength
              bath
              bed
              garage
              living
              firstFloorImagePath
              groundFloorImagePath
              GroundFloorRoomsAmenities {
                UndigitalRoomAmenity {
                  name
                  objectID
                }
              }
              Level1RoomsAmenities {
                UndigitalRoomAmenity {
                  name
                  objectID
                }
              }
            }
          }
          Designers {
            UndigitalDesigner {
              name
            }
          }
          Storeys {
            UndigitalStoreys {
              name
            }
          }
          Disclaimer {
            UndigitalTextAsset {
              title
              content
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = state => ({
  currentBoards: state.board.all,
})

export default connect(mapStateToProps, null)(HomeDesignDetail)
